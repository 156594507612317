import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateCarrier } from "redux/actions/carrier.action";
import ModalFooterUpdateClose from "../components/ModalFooterUpdateClose";
import { Typeahead } from "react-bootstrap-typeahead";
import country from "assets/data/Country";
import { validateEmail } from "utils/helperFunctions";

class CarrierUpdateForm extends React.Component {
  state = {
    data: {
       country: "US",
    },
    emailError: "",
  };

  updateCarrier(field, value) {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        [field]: value,
      },
    }));
  }

  handleSubmit(e) {
    e.preventDefault();
    const { email } = this?.state?.data;

    // Email validation
    if (email && !validateEmail(email)) {
      this.setState({ emailError: "Invalid email address format" });
      return;
    }

    // Clear error if email is valid
    this.setState({ emailError: "" });

    // Proceed with form submission
    this.props.updateCarrier(this.state.data);
    this.closeModal();
  }

  closeModal() {
    this.closeButton.click();
  }

  render() {
    const {
      modal: { selectedData },
    } = this.props;

    const { emailError } = this.state;

    // Set default data
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.data.id = selectedData.id;

    return (
      <>
        <form onSubmit={(e) => this.handleSubmit(e)}>
          <div className="modal-body">
            <div className="form-row">
              <div className="col-md-3 mb-3">
                <label htmlFor="firstname">Name</label>
                <input
                  type="text"
                  pattern="^[A-Za-z\s]+$"
                  title="Name can only contain letters and spaces."
                  className="form-control"
                  id="firstname"
                  placeholder="Carrier name"
                  defaultValue={selectedData.name}
                  onChange={(e) => this.updateCarrier("name", e.target.value)}
                  required
                />
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="contact_name">Contact</label>
                <input
                  type="text"
                  className="form-control"
                  id="contact_name"
                  defaultValue={selectedData.contact_name}
                  placeholder="Contact"
                  onChange={(e) =>
                    this.updateCarrier("contact_name", e.target.value)
                  }
                  required
                />
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="phone">Phone</label>
                <input
                  type="text"
                  minLength="8"
                  maxLength="25"
                  pattern="[0-9]{10}"
                  title="Enter a valid number"
                  className="form-control"
                  id="phone"
                  placeholder="000 000 0000"
                  defaultValue={selectedData.phone}
                  onChange={(e) => this.updateCarrier("phone", e.target.value)}
                  required
                />
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="email">Email</label>
                <div className="input-group">
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Email"
                    defaultValue={selectedData.email}
                    onChange={(e) =>
                      this.updateCarrier("email", e.target.value)
                    }
                    aria-describedby="inputGroupPrepend"
                    required
                  />
                </div>
                {emailError && <div className="error-color">{emailError}</div>}
              </div>
            </div>

            <div className="form-row">
              <div className="col-md-6 mb-3">
                <label htmlFor="address">Address</label>
                <input
                  type="text"
                  className="form-control"
                  id="address"
                  defaultValue={selectedData.address}
                  onChange={(e) =>
                    this.updateCarrier("address", e.target.value)
                  }
                  placeholder="Address"
                  required
                />
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="City">City</label>
                <input
                  type="text"
                  pattern="[A-Za-z\s]{1,30}"
                  className="form-control"
                  id="city"
                  defaultValue={selectedData.city}
                  onChange={(e) => this.updateCarrier("city", e.target.value)}
                  placeholder="City"
                  required
                />
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="zipcode">Zipcode</label>
                <input
                  type="text"
                  pattern="[A-Za-z0-9\s\-.]*"
                  title="Invalid ZIP code format. Use 12345 or 12345-6789."
                  className="form-control"
                  id="zipcode"
                  defaultValue={selectedData.zipcode}
                  onChange={(e) =>
                    this.updateCarrier("zipcode", e.target.value)
                  }
                  placeholder="Zipcode"
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-3 mb-3">
                <label htmlFor="Country">Country </label>
                 <input
                  type="text"
                  pattern="[A-Za-z]+"
                  maxLength={15}
                  className="form-control"
                  id="Country"
                  placeholder="Country"
                  defaultValue={selectedData.country}
                  onChange={(e) => {
                    this.updateCarrier("country", e.target.value);
                  }}
                  required
                />
                {/* <Typeahead
                  id="Country"
                  paginationText="NEXT PAGE"
                  multiple={false}
                  options={country.map((i) => i.code)}
                  placeholder="Country"
                  defaultInputValue={selectedData.country}
                  onChange={(e) => {
                    this.updateCarrier("country", e[0]);
                  }}
                  onInputChange={(e) => this.updateCarrier("country", e)}
                /> */}
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="state">State</label>
                <input
                  type="text"
                  pattern="[A-Za-z]+"
                  maxLength={25}
                  className="form-control"
                  id="State"
                  placeholder="State Like AL"
                  defaultValue={selectedData.state}
                  onChange={(e) => {
                    this.updateCarrier("state", e.target.value);
                  }}
                  required
                />
              </div>
              {this.props.user.user?.vendor ? (
                ""
              ) : (
                <>
                  <div className="col-md-3 mb-3 pr-5">
                    <label htmlFor="consolidator">Consolidator</label>
                    <div className="pr-5">
                      <input
                        type="checkbox"
                        className="form-control"
                        id="consolidator"
                        defaultChecked={selectedData.xml}
                        onChange={(e) => {
                          const value =
                            e.target.type === "checkbox"
                              ? e.target.checked
                              : e.target.value;
                          this.updateCarrier("xml", value === true ? 1 : 0);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-1 mb-3">
                    <label htmlFor="default">Default</label>
                    <input
                      type="checkbox"
                      className="form-control"
                      id="default"
                      defaultChecked={selectedData.default}
                      onChange={(e) => {
                        const value =
                          e.target.type === "checkbox"
                            ? e.target.checked
                            : e.target.value;
                        this.updateCarrier("default", value === true ? 1 : 0);
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <ModalFooterUpdateClose
            inputRef={(button) => (this.closeButton = button)}
          />
        </form>
      </>
    );
  }
}

export default connect(
  ({ modal, vendor, carrier, user }) => ({ modal, vendor, carrier, user }),
  (dispatch) => bindActionCreators({ updateCarrier }, dispatch)
)(CarrierUpdateForm);
