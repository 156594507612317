import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addVendor } from "redux/actions/vendor.action";
import { validateEmail } from "utils/helperFunctions";

class VendorModalForm extends React.Component {
  initialState() {
    return {
      data: {
        vendor_number: "",
        name: "",
        email: "",
        client_id: this.props.user.user.isSuper()
          ? ""
          : this.props.user.user.client.id,
      },
      emailError: "",
    };
  }

  state = this.initialState();

  updateVendor(field, value) {
    let trimmedValue = value;

    trimmedValue =
      value === "" || value.trim() !== "" ? value : this.state.data[field];

    let st = this.state;
    st.data[field] = trimmedValue;
    this.setState(st);
  }
  closeModal() {
    this.closeButton.click();
    this.setState(this.initialState());
  }
  clearFields() {
    this.setState(this.initialState());
  }

  handleSubmit() {
    const { email, name, vendor_number } = this.state.data;
    const trimmedName = name?.trim();
    const trimmedVendorNumber = vendor_number?.trim();

    // Email validation
    if (email && !validateEmail(email)) {
      this.setState({ emailError: "Invalid email address format" });
      return;
    }

    // Clear error if email is valid
    this.setState({ emailError: "" });

    // Proceed with form submission
    this.props.addVendor({
      ...this.state?.data,
      vendor_number: trimmedVendorNumber,
      name: trimmedName,
    });
    this.clearFields();
    this.closeModal();
  }
  render() {
    const {
      user: { user },
      client: { clients },
    } = this.props;
    const { data, emailError } = this.state;
    const clientId = this.state.data.client_id;
    return (
      <>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.handleSubmit();
          }}
        >
          <div className="modal-body">
            <div className="form-row">
              <div className="form-group col-md-3">
                <label htmlFor="inputVendorId">Vendor Number</label>
                <input
                  type="text"
                  className="form-control"
                  id="inputVendorId"
                  required
                  placeholder="Vendor Number"
                  value={data.vendor_number}
                  maxLength={8}
                  pattern="\d{1,10}"
                  title="Vendor Number must be a number with a maximum length of 10 digits."
                  onChange={(e) =>
                    this.updateVendor("vendor_number", e.target.value)
                  }
                />
              </div>
              <div className="form-group col-md-3">
                <label htmlFor="inputName">Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="inputName"
                  required
                  placeholder="Name"
                  value={data.name}
                  onChange={(e) => this.updateVendor("name", e.target.value)}
                />
              </div>
              {user && user.isSuper() && (
                <div className="col-md-3 mb-3">
                  <label htmlFor="vendor">Client</label>
                  <select
                    className="form-control"
                    required
                    value={clientId}
                    onChange={(e) =>
                      this.updateVendor(
                        "client_id",
                        e.target.value === "select a client"
                          ? null
                          : e.target.value,
                      )
                    }
                  >
                    <option value="" selected>
                      select a client
                    </option>
                    {clients.map((c) => (
                      <option key={c.id} value={c.id}>
                        {c.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              <div className="form-group col-md-4">
                <label htmlFor="inputEmail">Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="inputEmail"
                  required
                  placeholder="Email"
                  value={data.email}
                  onChange={(e) => this.updateVendor("email", e.target.value)}
                />
                {emailError && <div className="error-color">{emailError}</div>}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => this.clearFields()}
                data-dismiss="modal"
                ref={(button) => (this.closeButton = button)}
              >
                Close
              </button>
              <button type="submit" className="btn btn-primary">
                Add
              </button>
            </div>
          </div>
        </form>
      </>
    );
  }
}
export default connect(
  ({ modal, user, vendor, client }) => ({ modal, user, vendor, client }),
  (dispatch) => bindActionCreators({ addVendor }, dispatch),
)(VendorModalForm);
