export const isIntegerInput = (input) => {
  if (isNaN(input)) {
    return false;
  }

  const number = parseFloat(input);
  return Number.isInteger(number);
};

export const isAsnSaveable = (currentPo, lines, weight, totalCarton) => {
  let errors = [];

  if (weight <= 0) {
    errors.push({ error: "Invalid weight" });
  }

  if (totalCarton < 1) {
    errors.push({
      error: "Total cartons is required and must be greater than 0",
    });
  }

  if (!isIntegerInput(totalCarton)) {
    errors.push({
      error: "Invalid Total Cartons value. Decimal values are not allowed.",
    });
  }

  const asnsLines = lines.map((v) => {
    const obj = v.getAsnDetail();
    if (!obj.hasOwnProperty("quantity")) {
      obj.quantity = obj?.remaining_qty;
    }

    if (v?.poLine?.active === false) {
      errors.push({
        model: v,
        error: "Line has been deactivated, Please Remove before saving.",
      });
    }

    return obj;
  });

  for (const line of asnsLines) {
    if (line?.quantity < 1) {
      errors.push({
        error: "PO line quantity should not be 0.",
      });
      break; // Assuming we stop checking further once an error is found
    }

    if (line?.quantity > line?.remaining_qty) {
      errors.push({
        error:
          "You can only add a quantity that is less than the remaining quantity.",
      });
      break; // Assuming we stop checking further once an error is found
    }

    if (!isIntegerInput(line?.quantity)) {
      errors.push({
        error: "Invalid quantity. Decimal values are not allowed.",
      });
      break; // Assuming we stop checking further once an error is found
    }
  }

  return [currentPo.id, asnsLines, errors.length && errors];
};

export const getEarliestCancelDate = (cancelDatesArray) => {
  // Convert Set to array for easier manipulation
  // const cancelDatesArray = Array.from(cancelDatesSet);

  // If the set is empty, return null
  if (cancelDatesArray.length === 0) {
    return null;
  }

  // Initialize earliestDate to the first date in the array
  let earliestDate = new Date(cancelDatesArray[0]);

  // Iterate through the array to find the earliest date
  for (let i = 1; i < cancelDatesArray.length; i++) {
    const currentDate = new Date(cancelDatesArray[i]);
    if (currentDate < earliestDate) {
      earliestDate = currentDate;
    }
  }

  return earliestDate.toISOString().split("T")[0]; // Return in "YYYY-MM-DD" format
};

export const getEarliestCancelDateBetweenTwo = (cancelDate1, cancelDate2) => {
  if (!cancelDate1 && !cancelDate2) return null;

  // If one of the dates is null, return the non-null date
  if (!cancelDate1) return cancelDate2;
  if (!cancelDate2) return cancelDate1;

  const earliestDate =
    new Date(cancelDate1) < new Date(cancelDate2) ? cancelDate1 : cancelDate2;
  return earliestDate;
};

export const removeCRLF = (input) => {
  if (input == null) {
    return input; // Return null or undefined as is
  }
  return input.replace(/[\r\n]/g, " ");
};
